/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import useData from "../../Utils/useData/index";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgGaleria2 from "../../Images/ImgGaleria2.jpg";

function Video(props: any) {
  const [data, setData] = useState<any>([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const loadData = () => {
    useData()
      .getData("videos.php")
      .then((response: any) => {
        setData(response);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      loadData();
    }, 1000);
  }, []);

  return (
    <>
      <div id="Videos"></div>
      <S.BgVideo>
        <S.OutVideo>
          <Slider className="SliderVideo" {...settings}>
            {data &&
              data.map((data: any, index: React.Key | null | undefined) => (
                <S.ItemVideo key={index}>
                  <iframe
                    src={"https://www.youtube.com/embed/" + data.c_video}
                    width="100%"
                    className="IframeVideo"
                    frameBorder="0"
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"></iframe>
                  <S.RightVideo>
                    <S.TextVideo1>{data.c_titulo}</S.TextVideo1>
                    <S.TextVideo2>
                      {data.c_texto}
                    </S.TextVideo2>
                  </S.RightVideo>
                </S.ItemVideo>
              ))}
          </Slider>
        </S.OutVideo>
      </S.BgVideo>
    </>
  );
}

export default withRouter(Video);
