import styled from "styled-components";
import ImgBgAlbum from "../../Images/BgAlbum.jpg";
import ImgBgAlbumResp from "../../Images/BgAlbumResp.jpg";

export const BgAlbum = styled.section`
  background-image: url(${ImgBgAlbum});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 900px) {
    background-image: url(${ImgBgAlbumResp});
  }
`;

export const OutAlbum = styled.div`
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6vw;
  padding-bottom: 18vw;

  @media (max-width: 1450px) {
    padding-top: 3vw;
    padding-bottom: 13vw;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const LeftAlbum = styled.div`
  width: 40%;
  padding-top: 6vw;

  @media (max-width: 1450px) {
    padding-top: 8vw;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const LeftAlbumResp = styled.div`
  width: 40%;
  padding-top: 6vw;
  display: none;

  @media (max-width: 1450px) {
    padding-top: 8vw;
  }

  @media (max-width: 900px) {
    width: 400px;
    padding-top: 0vw;
    display: block;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const RightAlbum = styled.div`
  width: 54%;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 0px;
    padding-bottom: 35px;
  }
`;

export const TextAlbum1 = styled.div`
  color: #ad8666;
  font-size: 75px;
  font-weight: 800;
  text-transform: uppercase;
  padding-bottom: 35px;

  @media (max-width: 1450px) {
    font-size: 58px;
    padding-bottom: 25px;
  }

  @media (max-width: 500px) {
    font-size: 40px;
  }
`;

export const TextAlbum2 = styled.div`
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  height: 30vw;
  overflow: hidden scroll;
  padding-right: 30px;
  color: rgb(255, 255, 255);

  @media (max-width: 1450px) {
    height: 34vw;
  }

  @media (max-width: 900px) {
    height: 330px;
    padding-right: 10px;
    font-size: 20px;
    line-height: 26px;
  }
`;
