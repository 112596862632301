/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import useData from "../../Utils/useData";

import ImgLogo from "../../Images/Logo.png";

function Header(props: any) {
  const history = useHistory();
  const [menu, setMenu] = useState(0);
  const [data, setData] = useState(0);
  const [load, setLoad] = useState(false);

  const MandaFace = () => {
    window.open(
      "https://www.facebook.com/publistand.empreendimentos/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0",
      "_blank",
    );
  };

  const MandaHome = () => {
    history.push("/Producao");
    setMenu(0);
  };

  useEffect(() => {
    const hash = props.history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1))?.scrollIntoView({ behavior: "smooth" });
    }
    // }, []);
  }, [props.history.location.hash]);

  return (
    <>
      <S.BgTop>
        <S.OutTop>
          <S.LogoTop>
            <img src={ImgLogo} />
          </S.LogoTop>
          <iframe
            className="IframeBanner"
            src={"https://www.youtube.com/embed/GDzI76yxhPo"}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
            gyroscope; picture-in-picture; web-share"
            allowFullScreen></iframe>
        </S.OutTop>
      </S.BgTop>

      {data == 0 && (
        <S.OutBotaoMobile onClick={() => setData(1)}>
          <S.RiskMenuMobile />
          <S.RiskMenuMobile />
          <S.RiskMenuMobile />
        </S.OutBotaoMobile>
      )}

      {data == 1 && (
        <S.BgMenuMobile>
          <S.XMenuMobile onClick={() => setData(0)}>X</S.XMenuMobile>
          <S.OutMenuMobile>
            <S.LogoMobile>
              <img src={ImgLogo} />
            </S.LogoMobile>
            <S.MenuMobile>
              <S.TextMenuMobile href="#Album" onClick={() => setData(0)}>
                NOVO ÁLBUM
              </S.TextMenuMobile>
              <S.TextMenuMobile href="#Agenda" onClick={() => setData(0)}>
                AGENDA
              </S.TextMenuMobile>
              <S.TextMenuMobile href="#Fotos" onClick={() => setData(0)}>
                FOTOS
              </S.TextMenuMobile>
              <S.TextMenuMobile href="#Videos" onClick={() => setData(0)}>
                VÍDEOS
              </S.TextMenuMobile>
              <S.TextMenuMobile href="#RafaelBeck" onClick={() => setData(0)}>
                RAFAEL BECK
              </S.TextMenuMobile>
              <S.TextMenuMobile href="#FelipeMontanaro" onClick={() => setData(0)}>
                FELIPE MONTANARO
              </S.TextMenuMobile>
              <S.TextMenuMobile href="#Contato" onClick={() => setData(0)}>
                CONTATO
              </S.TextMenuMobile>
            </S.MenuMobile>
          </S.OutMenuMobile>
        </S.BgMenuMobile>
      )}
    </>
  );
}

export default withRouter(Header);
