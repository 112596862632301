import styled from "styled-components";
import ImgBgVideo from "../../Images/BgVideo.jpg";

export const BgVideo = styled.section`
  background-image: url(${ImgBgVideo});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 900px) {
    background-image: unset;
    background-color: #0f0f0f;
  }
  
`;

export const ForaImgVideo = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const OutVideo = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-top: -2vw;
  padding-bottom: 13vw;

  @media (max-width: 1100px) {
    width: 89%;
  }
`;

export const ItemVideo = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const RightVideo = styled.div`
  width: 27%;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 30px;
  }
`;

export const TextVideo1 = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: #ad8666;

  @media (max-width: 1100px) {
    font-size: 23px;
  }
`;

export const TextVideo2 = styled.div`
  font-size: 22px;
  font-weight: 300;
  margin-top: 35px;
  overflow: hidden scroll;
  padding-right: 30px;
  color: rgb(255,255,255);
  max-height: 300px;

  @media (max-width: 1300px) {
    font-size: 20px;
    margin-top: 15px;
    max-height: 23vw;
  }  
  
  @media (max-width: 1100px) {
    font-size: 18px;
  }  
  
  @media (max-width: 900px) {
    max-height: 18vw;
  }    
  
  @media (max-width: 700px) {
    max-height: 30vw;
  }  
  
  @media (max-width: 500px) {
    max-height: 180px;
  }
`;
