import styled from "styled-components";
import ImgBgGaleria from "../../Images/BgGaleria.jpg";
import ImgVideo from "../../Images/ImgVideo.jpg";

export const OutGaleria = styled.section``;

export const OutImgGaleria = styled.div`
  background-image: url(${ImgBgGaleria});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 27vw;

  @media (max-width: 900px) {
    background-image: url(${ImgVideo});
    height: 21vw;
  }
`;

export const ForaimgGaleria = styled.div`
  margin-bottom: -4px;
  filter: grayscale(1);

  :hover {
    cursor: pointer;
    filter: grayscale(0);
  }
`;

export const BgImgMaior = styled.div`
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100000;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
`;

export const XImgMaior = styled.div`
  position: absolute;
  right: 35px;
  top: 15px;
  font-size: 27px;
  font-weight: 800;
  cursor: pointer;
`;

export const OutImgMaior = styled.div`
  width: 500px;
  margin: 0 auto;

  @media (max-width: 1400px) {
    width: 390px;
  }  
  
  @media (max-width: 450px) {
    width: 90%;
  }
`;
