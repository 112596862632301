import styled from "styled-components";
import ImgBgContato from "../../Images/BgContato.jpg";

export const BgContact = styled.section`
  background-image: url(${ImgBgContato});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 1px;
`;

export const OutRedes = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: inherit;
  margin-top: -130px;

  @media (max-width: 1200px) {
    width: 85%;
  }  
  
  @media (max-width: 700px) {
    margin-top: -17vw;
  }
`;

export const ItemRedes = styled.div`
  cursor: pointer;
`;

export const OutContact = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  padding-top: 11vw;

  @media (max-width: 1200px) {
    padding-top: 5vw;
  }  
  
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }  
  
  @media (max-width: 520px) {
    width: 85%;
    padding-top: 8vw;
  }
`;

export const LeftContact = styled.div`
  width: 59%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const TextContact1 = styled.div`
  font-size: 75px;
  font-weight: 800;
  text-transform: uppercase;
  color: #ad8666;

  @media (max-width: 900px) {
    font-size: 55px;
  }  
  
  @media (max-width: 520px) {
    font-size: 40px;
  }
`;

export const RightContact = styled.div`
  width: 390px;
  padding-left: 5vw;
  padding-top: 50px;

  @media (max-width: 1200px) {
    width: 320px;
  }  
  
  @media (max-width: 900px) {
    padding-left: 0vw;
    padding-top: 0px;
  }
`;

export const ItemContact = styled.div`
  padding-top: 45px;

  @media (max-width: 900px) {
    padding-top: 25px;
  }
`;

export const TextContact2 = styled.div`
  font-size: 28px;
  font-weight: 800;
  color: #ad8666;

  @media (max-width: 1200px) {
    font-size: 26px;
  }
`;

export const TextContact3 = styled.div`
  font-weight: 300;
  font-size: 28px;

  @media (max-width: 1200px) {
    font-size: 23px;
  }
`;

export const OutImgContact = styled.div`
  width: 600px;
  margin: 0 auto;
  padding-top: 10vw;
  padding-bottom: 10vw;

  @media (max-width: 900px) {
    width: 420px;
    padding-top: 8vw;
    padding-bottom: 8vw;
  }  
  
  @media (max-width: 520px) {
    width: 85%;
  }
`;
