/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgGaleria1 from "../../Images/ImgGaleria1.jpg";
import ImgGaleria2 from "../../Images/ImgGaleria2.jpg";

function Galeria(props: any) {
  const [foto, setFoto] = useState("");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },   
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }, {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  return (
    <>
      <div id="Fotos"></div>
      <S.OutGaleria>
        <Slider className="SliderGaleria" {...settings}>
          <S.ForaimgGaleria onClick={() => setFoto("https://www.duobeckmontanaro.com.br/upload/Fotos/00.jpg")}><img src="https://www.duobeckmontanaro.com.br/upload/Fotos/00a.jpg" /></S.ForaimgGaleria>
          <S.ForaimgGaleria onClick={() => setFoto("https://www.duobeckmontanaro.com.br/upload/Fotos/01.jpg")}><img src="https://www.duobeckmontanaro.com.br/upload/Fotos/01a.jpg" /></S.ForaimgGaleria>
          <S.ForaimgGaleria onClick={() => setFoto("https://www.duobeckmontanaro.com.br/upload/Fotos/02.jpg")}><img src="https://www.duobeckmontanaro.com.br/upload/Fotos/02a.jpg" /></S.ForaimgGaleria>
          <S.ForaimgGaleria onClick={() => setFoto("https://www.duobeckmontanaro.com.br/upload/Fotos/03.jpg")}><img src="https://www.duobeckmontanaro.com.br/upload/Fotos/03a.jpg" /></S.ForaimgGaleria>
          <S.ForaimgGaleria onClick={() => setFoto("https://www.duobeckmontanaro.com.br/upload/Fotos/04.jpg")}><img src="https://www.duobeckmontanaro.com.br/upload/Fotos/04a.jpg" /></S.ForaimgGaleria>
          <S.ForaimgGaleria onClick={() => setFoto("https://www.duobeckmontanaro.com.br/upload/Fotos/05.jpg")}><img src="https://www.duobeckmontanaro.com.br/upload/Fotos/05a.jpg" /></S.ForaimgGaleria>
          <S.ForaimgGaleria onClick={() => setFoto("https://www.duobeckmontanaro.com.br/upload/Fotos/06.jpg")}><img src="https://www.duobeckmontanaro.com.br/upload/Fotos/06a.jpg" /></S.ForaimgGaleria>
          <S.ForaimgGaleria onClick={() => setFoto("https://www.duobeckmontanaro.com.br/upload/Fotos/07.jpg")}><img src="https://www.duobeckmontanaro.com.br/upload/Fotos/07a.jpg" /></S.ForaimgGaleria>
          <S.ForaimgGaleria onClick={() => setFoto("https://www.duobeckmontanaro.com.br/upload/Fotos/08.jpg")}><img src="https://www.duobeckmontanaro.com.br/upload/Fotos/08a.jpg" /></S.ForaimgGaleria>
        </Slider>
      </S.OutGaleria>
      <S.OutImgGaleria />

      {foto !== "" && (
      <S.BgImgMaior>
        <S.XImgMaior onClick={() => setFoto("")}>X</S.XImgMaior>
        <S.OutImgMaior>
          <img src={foto} />
        </S.OutImgMaior>
      </S.BgImgMaior>
      )}
    </>
  );
}

export default withRouter(Galeria);
