/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import useData from "../../Utils/useData/index";

import ReactHtmlParser from 'react-html-parser';

import ImgAgenda from "../../Images/ImgAgenda.jpg";

function Agenda(props: any) {
  const [data, setData] = useState<any>([]);
  const history = useHistory();

  const loadData = () => {
    useData()
      .getData("agenda.php")
      .then((response: any) => {
        setData(response);
        console.log(response);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      loadData();
    }, 1000);
  }, []);

  return (
    <>
      <div id="Agenda"></div>
      <S.OutImgAgenda>
        <img src={ImgAgenda} />
      </S.OutImgAgenda>
      <S.BgAgenda>
        <S.OutAgenda>
          <S.LeftAgenda>
            <S.TextAgenda1>Agenda</S.TextAgenda1>
          </S.LeftAgenda>
          <S.RightAgenda>
            {data &&
              data.map((data: any, index: React.Key | null | undefined) => (
                <S.ItemAgenda key={index}>
                  <S.LeftTextAgenda>
                   <S.TextAgenda2Resp>{data.c_data.substr(6, 2)}<span> / </span>{data.c_data.substr(4, 2)}<span> / </span>{data.c_data.substr(0, 4)}</S.TextAgenda2Resp>
                   <S.TextAgenda2>{data.c_data.substr(6, 2)}<span> / </span>{data.c_data.substr(4, 2)}</S.TextAgenda2>
                   <S.TextAgenda2Baixo>{data.c_data.substr(0, 4)}</S.TextAgenda2Baixo>
                  </S.LeftTextAgenda>                
                  <S.RightTextAgenda>
                    <S.TextAgenda3>{data.c_cidade}</S.TextAgenda3>
                    <S.TextAgenda4>{data.c_local}</S.TextAgenda4>
                    {data.c_link !== null && data.c_link !== "" && (
                    <S.ButtonAgenda target="_blank" href={data.c_link}>LINK INGRESSOS</S.ButtonAgenda>
                    )}
                    <S.TextAgenda5><span>Horário: </span>{data.c_horario}</S.TextAgenda5>
                    <S.TextAgenda6>{data.c_observacao}</S.TextAgenda6>
                  </S.RightTextAgenda>
                </S.ItemAgenda>
              ))}

           {/*
            <S.ItemAgenda>
              <S.TextAgenda2>
                27 <span>/</span> 07
              </S.TextAgenda2>
              <S.RightTextAgenda>
                <S.TextAgenda3>Natura Musical</S.TextAgenda3>
                <S.TextAgenda4>Abertura do show do Hermeto Pascoal</S.TextAgenda4>
              </S.RightTextAgenda>
            </S.ItemAgenda>
           */}

          </S.RightAgenda>
        </S.OutAgenda>
      </S.BgAgenda>
    </>
  );
}

export default withRouter(Agenda);
