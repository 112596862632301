import styled from "styled-components";
import ImgBgAgenda from "../../Images/BgAgenda.jpg";
import ImgBgAgendaResp from "../../Images/BgAgendaResp.jpg";

export const BgAgenda = styled.section`
  background-image: url(${ImgBgAgenda});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 900px) {
    background-image: url(${ImgBgAgendaResp});
  }
`;

export const OutImgAgenda = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
    margin-bottom: -4px;
  }
`;

export const OutAgenda = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 19.4vw;
  padding-top: 2vw;

  @media (max-width: 1433px) {
    padding-bottom: 16.4vw;
  }

  @media (max-width: 900px) {
    padding-top: 35px;
    flex-direction: column;
    padding-bottom: 50px;
    width: 89%;
  }
`;

export const LeftAgenda = styled.div`
  width: 40%;

  @media (max-width: 1433px) {
    width: 37%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const TextAgenda1 = styled.div`
  font-size: 75px;
  font-weight: 800;
  text-transform: uppercase;
  color: #ad8666;
  text-align: right;

  @media (max-width: 1433px) {
    font-size: 52px;
  }

  @media (max-width: 1000px) {
    font-size: 44px;
    text-align: center;
    margin-bottom: 30px;
  }
`;

export const RightAgenda = styled.div`
  width: 55%;
  margin-top: -20px;
  height: 35vw;
  overflow: hidden scroll;
  padding-right: 30px;
  color: rgb(255, 255, 255);

  @media (max-width: 1433px) {
    padding-right: 13px;
  }

  @media (max-width: 900px) {
    width: 99%;
    height: 56vw;
    padding-right: 31px;
  }

  @media (max-width: 500px) {
    width: 95%;
    height: 111vw;
  }
`;

export const ItemAgenda = styled.div`
  border-bottom: 1px solid #ad8666;
  padding-bottom: 22px;
  display: flex;
  padding-left: 20px;
  padding-top: 20px;

  @media (max-width: 1433px) {
    padding-bottom: 16px;
    padding-top: 15px;
  }

  @media (max-width: 500px) {
    padding-left: 0px;
    flex-direction: column;
  }
`;

export const TextAgenda2 = styled.div`
  font-size: 70px;
  font-weight: 800;
  text-transform: uppercase;
  width: 225px;

  span {
    color: #ad8666;
    margin-left: -8px;
    margin-right: -11px;
  }

  @media (max-width: 1615px) {
    font-size: 55px;
    width: 180px;
  }

  @media (max-width: 1433px) {
    font-size: 39px;
    width: 129px;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const TextAgenda2Resp = styled.div`
  font-size: 70px;
  font-weight: 800;
  text-transform: uppercase;
  width: 212px;
  display: none;

  span {
    color: #ad8666;
    margin-left: -15px;
    margin-right: -11px;
  }

  @media (max-width: 500px) {
    display: block;
    font-size: 32px;

    span {
      margin-left: -6px;
      margin-right: -4px;
    }
  }
`;

export const TextAgenda2Baixo = styled.div`
  font-size: 36px;
  font-weight: 400;
  text-transform: uppercase;
  width: 212px;
  margin-top: -10px;

  span {
    color: #ad8666;
    margin-left: -15px;
    margin-right: -11px;
  }

  @media (max-width: 1615px) {
    font-size: 32px;
    width: 160px;
  }

  @media (max-width: 1433px) {
    font-size: 28px;
    width: 110px;
    margin-top: -6px;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const LeftTextAgenda = styled.div``;

export const RightTextAgenda = styled.div`
  padding-left: 4%;
  width: 69%;

  @media (max-width: 1615px) {
    width: 73%;
  }

  @media (max-width: 500px) {
    width: 100%;
    padding-left: 0%;
  }
`;

export const TextAgenda3 = styled.div`
  font-size: 40px;
  font-weight: 800;
  padding-top: 9px;

  @media (max-width: 1615px) {
    font-size: 35px;
    padding-top: 5px;
  }

  @media (max-width: 1433px) {
    font-size: 28px;
    padding-top: 2px;
  }

  @media (max-width: 500px) {
    font-size: 26px;
  }
`;

export const TextAgenda4 = styled.div`
  font-size: 25px;
  font-weight: 300;
  color: #ad8666;
  margin-top: 5px;
  margin-bottom: 5px;

  @media (max-width: 1615px) {
    font-size: 22px;
  }

  @media (max-width: 1433px) {
    font-size: 19px;
    line-height: 19px;
    margin-bottom: 10px;
  }
`;

export const ButtonAgenda = styled.a`
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ad8666;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 225px;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 1615px) {
    font-size: 22px;
  }

  @media (max-width: 1433px) {
    font-size: 18px;
    line-height: 19px;
    margin-bottom: 10px;
    height: 30px;
  }
`;

export const TextAgenda5 = styled.div`
  font-size: 25px;
  font-weight: 300;
  color: #ad8666;
  margin-bottom: 5px;

  span {
    font-weight: 800;
    color: #fff;
  }

  @media (max-width: 1615px) {
    font-size: 22px;
  }

  @media (max-width: 1433px) {
    font-size: 19px;
    line-height: 19px;
    margin-bottom: 10px;
  }
`;

export const TextAgenda6 = styled.div`
  font-size: 25px;
  font-weight: 800;
  color: #fff;

  @media (max-width: 1615px) {
    font-size: 22px;
  }

  @media (max-width: 1433px) {
    font-size: 19px;
    line-height: 19px;
  }
`;
