import React, { useEffect, useState } from "react";
import * as S from "./estilos";

import useData from "../../Utils/useData";

// import RiscoItem from "../../Images/RiscoItem.png";

function Footer() {
  const [data, setData] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);
  const [load, setLoad] = useState(false);

  const MandaInsta = () => {
    window.open("https://www.instagram.com/publistand/", "_blank");
  };  
  

/*
  const loadData = () => {
    useData()
      .getData("parceiros.php")
      .then((response: any) => {
        // alert(response);
        setData(response);
      })
      .catch((err) => {
        console.info(err);
      });

    useData()
      .getData("arquitetos.php")
      .then((response: any) => {
        // alert(response);
        setData2(response);
      })
      .catch((err) => {
        console.info(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);
*/
  return (
    <>
  
    </>
  );
}

export default Footer;
