/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

function Biografias(props: any) {
  return (
    <>
      <div id="RafaelBeck"></div>
      <S.BgBiografia1>
        <S.OutBiografia>
          <S.LeftBiografia>
            <S.TextBiografia1>Rafael Beck</S.TextBiografia1>
            <S.TextBiografia2>
              O músico, hoje com 22 anos, iniciou seus estudos de flauta doce aos seis anos de idade. Aos sete, gravou
              seu primeiro CD, com um repertório bastante eclético que ia de Tom Jobim a Beatles, passando por Dorival
              Caymmi e Pixinguinha. Dos onze aos dezessete anos, cursou a Escola de Música do Estado de São Paulo -
              EMESP – Tom Jobim, ingressando em seguida na Faculdade Souza Lima, em São Paulo, onde em 2022 concluiu o
              curso e se formou Bacharel em música.
              <br />
              <br />
              Em 2017, junto com o violonista Rafael Schimidt, lançou pela gravadora Galeão, um CD em homenagem ao
              flautista Altamiro Carrilho, com um repertório autoral deste grande flautista.
              <br />
              <br />
              Com mais de sete anos de carreira, Rafael já teve participações em shows dos artistas Dominguinhos, Ivan
              Lins, Hermeto Pascoal, além de dividir palco com vários instrumentistas, entre eles, o genial Proveta.
              Participou de diversos Festivais de Música Instrumental por todo o país e shows em vários Sescs, incluindo
              o importante “Instrumental Sesc Brasil” que deu origem a um DVD, disponível no Canal do Sesc no Youtube.
            </S.TextBiografia2>
          </S.LeftBiografia>
        </S.OutBiografia>
      </S.BgBiografia1>

      <div id="FelipeMontanaro"></div>
      <S.BgBiografia2>
        <S.OutBiografia2>
          <S.LeftBiografia>
            <S.TextBiografia3>Felipe Montanaro</S.TextBiografia3>
            <S.TextBiografia4>
              Atualmente com 18 anos, iniciou os estudos musicais no piano aos oito e foi descobrindo outros
              instrumentos, como sanfona, baixo, violão e escaleta. Ao longo desses dez anos, estudou com músicos como
              André Marques, Itiberê Zwarg, Benjamim Taubkin e Toninho Ferragutti, além de um curso de férias na
              renomada Juilliard School.
              <br />
              <br />
              Em 2022 fez seu primeiro show solo de piano no Teatro Carlos Gomes em Bragança Paulista, onde tocou um
              repertorio que combinou composições próprias e releituras de compositores como Egberto Gismonti, Hermeto
              Pascoal e Astor Piazzolla.
              <br />
              <br />
              Felipe integra também o Trio Maniva, um grupo de música instrumental com composições autorais que trazem
              elementos da música brasileira como baião, frevo, samba e maracatu, além de releituras de consagrados
              artistas brasileiros.
            </S.TextBiografia4>
          </S.LeftBiografia>
        </S.OutBiografia2>
      </S.BgBiografia2>
    </>
  );
}

export default withRouter(Biografias);
