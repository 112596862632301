/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import ImgAlbum from "../../Images/ImgAlbum.jpg";

function Album(props: any) {
  const history = useHistory();

  return (
    <>
      <div id="Album"></div>
      <S.BgAlbum>
        <S.OutAlbum>
          <S.LeftAlbum>
            <img src={ImgAlbum} />
          </S.LeftAlbum>
          <S.RightAlbum>
            <S.TextAlbum1>Novo ÁLBUM</S.TextAlbum1>
            <S.TextAlbum2>
              O flautista Rafael Beck e o pianista Felipe Montanaro apresentam seu primeiro álbum, intitulado “Fantasia
              Brasil”.
              <br />
              <br />
              O projeto, que será lançado pela gravadora Biscoito Fino, traz um repertório com composições de grandes
              nomes da música brasileira, como Tom Jobim, Djavan, Hermeto Pascoal, Jacob do Bandolim e Ivan Lins, que
              inclusive participa da faixa de sua autoria “Galopes Dance”, tema que tinha guardado e ofereceu ao Duo.
              <br />
              <br />
              No medley de Tom Jobim com “Estrada do Sol” e “Chovendo na Roseira”, o piano tem momentos de influência do
              músico e compositor francês Claude Debussy.
              <br />
              <br />
              Outra inspiração é encontrada nos arranjos do renomado compositor e arranjador armênio Tigran Hamasyan,
              que serviram de base para a faixa “Pedro Brasil”.
              <br />
              <br />
              Já no choro “Receita de Samba”, a gravação traz um arranjo especial para dois pianos.
              <br />
              <br />
              Na faixa “Frevo Novo”, obra de Hermeto Pascoal, o duo criou um interlúdio musical em cima da harmonia do
              improviso.
              <br />
              <br />
              Além das faixas consagradas, o duo apresenta também sua primeira composição autoral, “Tema pra Ricardo”,
              que recebeu esse título em homenagem ao engenheiro de som Ricardo Camera do estúdio Trama NaCena onde o
              trabalho foi gravado.
            </S.TextAlbum2>
          </S.RightAlbum>
          <S.LeftAlbumResp>
            <img src={ImgAlbum} />
          </S.LeftAlbumResp>
        </S.OutAlbum>
      </S.BgAlbum>
    </>
  );
}

export default withRouter(Album);
