import styled from "styled-components";
import ImgBgTopo from "../../Images/BgTopo.jpg";

export const BgTop = styled.header`
  background-image: url(${ImgBgTopo});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const OutTop = styled.div`
  width: 90%;
  margin: 0 auto;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const LogoTop = styled.div`
  width: 460px;
  margin: 0 auto;
  padding-top: 65px;
  padding-bottom: 65px;

  @media (max-width: 1450px) {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  @media (max-width: 900px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (max-width: 700px) {
    width: 400px;
    margin: unset;
  }  
  
  @media (max-width: 560px) {
    width: 75%;
    margin: unset;
  }  
  
  @media (max-width: 500px) {
    width: 68%;
    margin: unset;
    padding-left: 6%;
  }
`;

export const OutBotaoMobile = styled.div`
  position: fixed;
  z-index: 1000;
  top: 25px;
  right: 45px;
  display: flex;
  flex-direction: column;
  height: 37px;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: 700px) {
    top: 43px;
    right: 6%;
  }  
  
  @media (max-width: 500px) {
    top: 4%;
    right: 6%;
    height: 32px;
  }
`;

export const RiskMenuMobile = styled.div`
  width: 53px;
  height: 4px;
  background-color: #fff;
  border-radius: 12px;

  @media (max-width: 500px) {
    width: 45px;
  }
`;

export const BgMenuMobile = styled.div`
  position: fixed;
  right: 0px;
  height: 100%;
  background-color: #ad8666;
  top: 0px;
  width: 380px;
  z-index: 1000;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const XMenuMobile = styled.div`
  font-size: 25px;
  font-weight: 800;
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
`;

export const OutMenuMobile = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: 60px;
`;

export const LogoMobile = styled.div``;

export const MenuMobile = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextMenuMobile = styled.a`
  font-size: 26px;
  font-weight: 800;
  margin-top: 13px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
`;
