import styled from "styled-components";
import ImgBgBio1 from "../../Images/BgBio1.jpg";
import ImgBgBio1Resp from "../../Images/BgBio1Resp.jpg";
import ImgBgBio2 from "../../Images/BgBio2.jpg";
import ImgBgBio2Resp from "../../Images/BgBio2Resp.jpg";
import ImgBgBio2Resp2 from "../../Images/BgBio2Resp2.jpg";

export const BgBiografia1 = styled.section`
  background-image: url(${ImgBgBio1});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 51vw;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    background-image: url(${ImgBgBio1Resp});
    height: auto;
    align-items: unset;
  }
`;

export const OutBiografia = styled.div`
  width: 85%;
  margin: 0 auto;

  @media (max-width: 900px) {
    padding-top: 6vw;
    padding-bottom: 6vw;
  }
`;

export const LeftBiografia = styled.div`
  width: 57%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const TextBiografia1 = styled.div`
  font-size: 75px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ad8666;

  @media (max-width: 1400px) {
    font-size: 60px;
  }

  @media (max-width: 1100px) {
    font-size: 48px;
  }

  @media (max-width: 500px) {
    font-size: 36px;
  }
`;

export const TextBiografia2 = styled.div`
  font-weight: 300;
  font-size: 22px;
  padding-top: 1vw;
  line-height: 31px;
  text-align: justify;

  @media (max-width: 1500px) {
    overflow: hidden scroll;
    padding-right: 30px;
    color: rgb(255, 255, 255);
    height: 33vw;
  }

  @media (max-width: 1100px) {
    font-size: 20px;
    padding-top: 2vw;
    line-height: 27px;
  }

  @media (max-width: 900px) {
    height: auto;
    overflow: auto;
    padding-right: 0px;
  }

  @media (max-width: 500px) {
    font-size: 17px;
    padding-top: 2vw;
    line-height: 20px;
  }
`;

export const BgBiografia2 = styled.section`
  background-image: url(${ImgBgBio2});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 58vw;
  display: flex;

  @media (max-width: 900px) {
    background-image: url(${ImgBgBio2Resp});
    height: auto;
    align-items: unset;
  } 
  
  @media (max-width: 600px) {
    background-image: url(${ImgBgBio2Resp2});
  }
`;

export const OutBiografia2 = styled.div`
  width: 81%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding-top: 8vw;

  @media (max-width: 1750px) {
    padding-top: 4vw;
  }

  @media (max-width: 1580px) {
    padding-top: 2vw;
  }  
  
  @media (max-width: 900px) {
    padding-top: 6vw;
    padding-bottom: 23vw;
  }
`;




export const TextBiografia3 = styled.div`
  font-size: 55px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ad8666;

  @media (max-width: 1400px) {
    font-size: 60px;
  }

  @media (max-width: 1100px) {
    font-size: 48px;
  }

  @media (max-width: 500px) {
    font-size: 36px;
  }
`;

export const TextBiografia4 = styled.div`
  font-weight: 300;
  font-size: 22px;
  padding-top: 1vw;
  line-height: 31px;
  text-align: justify;

  @media (max-width: 1500px) {
    overflow: hidden scroll;
    padding-right: 30px;
    color: rgb(255, 255, 255);
    height: 33vw;
  }  
  
  @media (max-width: 1400px) {
    height: 28vw;
  }

  @media (max-width: 1100px) {
    font-size: 20px;
    padding-top: 2vw;
    line-height: 27px;
  }

  @media (max-width: 900px) {
    height: auto;
    overflow: auto;
    padding-right: 0px;
  }

  @media (max-width: 500px) {
    font-size: 17px;
    padding-top: 2vw;
    line-height: 20px;
  }
`;
