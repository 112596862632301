/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import IconRedes1 from "../../Images/IconRedes1.png";
import IconRedes2 from "../../Images/IconRedes2.png";
import IconRedes3 from "../../Images/IconRedes3.png";
import IconRedes4 from "../../Images/IconRedes4.png";
import IconSeta from "../../Images/IconSeta.png";
import Logo from "../../Images/Logo.png";

import { Alert } from "../atoms";

function Contato(props: any) {
  const [load, setLoad] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [mensagem, setMensagem] = useState("");

  const phoneMask = (value: string) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d{4})/, "$1-$2");
  };

  const clearFields = () => {
    setNome("");
    setCelular("");
    setEmail("");
    setMensagem("");
  };

  const onSubmit = () => {
    let validaMenssages = [];
    validaMenssages.push(<div style={{ marginBottom: "10px" }}>Atenção, segue abaixo campos obrigatórios:</div>);
    let valida = false;
    if (nome === "" || nome === undefined) {
      validaMenssages.push(<div key={"Nome"}>- Nome</div>);
      valida = true;
    }
    if (celular === "" || celular === undefined) {
      validaMenssages.push(<div key={"Telefone"}>- Telefone</div>);
      valida = true;
    }
    // alert(captcha);
    // if (captcha === '' || captcha === undefined) {
    //   validaMenssages.push(<div key={'Telefone'}>- Verificação de robô</div>);
    //   valida = true;
    // }
    if (email === "" || email === undefined) {
      validaMenssages.push(<div key={"E-mail"}>- E-mail</div>);
      valida = true;
    } else {
      if (
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email,
        )
      ) {
        null;
      } else {
        validaMenssages.push(<div key={"E-mail"}>- E-mail (inválido)</div>);
        valida = true;
      }
    }
    if (mensagem === "" || mensagem === undefined) {
      validaMenssages.push(<div key={"Mensagem"}>- Mensagem</div>);
      valida = true;
    }
    if (valida) {
      Alert(<div>{validaMenssages}</div>);
    } else {
      setLoad(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        Nome: nome,
        Celular: celular,
        Email: email,
        Mensagem: mensagem,
      });

      validaMenssages = [];

      fetch("https://duobeckmontanaro.com.br/api/envia_contato.php", {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      })
        .then((response) => response.text())
        .then((response: any) => {
          // console.log(response);
          setLoad(false);
          Alert(
            <>
              <div>Formulário de contato enviado com sucesso!</div>
              <div>Em breve entraremos em contato.</div>
            </>,
          );
          clearFields();
          validaMenssages = [];
          // setNome('');
          // setEmail('');
          // setCelular('');
          // setMensagem('');
          setLoad(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const MandaInsta = () => {
    window.open("https://www.instagram.com/duobeckmontanaro/", "_blank");
  };

  const MandaYoutube = () => {
    window.open("https://www.youtube.com/@duobeckmontanaro", "_blank");
  };

  const MandaTikTok = () => {
    window.open("https://www.tiktok.com/@duobeckmontanaro", "_blank");
  };

  const MandaFace = () => {
    window.open("https://www.facebook.com/duobeckmontanaro/", "_blank");
  };

  return (
    <>
      <S.BgContact>
        <S.OutRedes>
          <S.ItemRedes onClick={() => MandaInsta()}>
            <img src={IconRedes1} />
          </S.ItemRedes>
          <S.ItemRedes onClick={() => MandaYoutube()}>
            <img src={IconRedes2} />
          </S.ItemRedes>
          <S.ItemRedes onClick={() => MandaTikTok()}>
            <img src={IconRedes3} />
          </S.ItemRedes>
          <S.ItemRedes onClick={() => MandaFace()}>
            <img src={IconRedes4} />
          </S.ItemRedes>
        </S.OutRedes>
        <S.OutContact>
          <div id="Contato"></div>
          <S.LeftContact>
            <S.TextContact1>Contato</S.TextContact1>
            <form className="FormContato">
              <input
                className="InputForm"
                type="text"
                onChange={(e) => setNome(e.target.value)}
                value={nome}
                name="c_nome"
                id="c_nome"
                placeholder="Nome"
              />
              <input
                className="InputForm"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                name="c_email"
                id="c_email"
                placeholder="E-mail"
              />
              <input
                className="InputForm"
                onChange={(e) => setCelular(e.target.value)}
                value={phoneMask(celular)}
                maxLength={15}
                name="c_telefone"
                id="c_telefone"
                placeholder="Telefone"
              />
              <textarea
                className="InputTextArea"
                onChange={(e) => setMensagem(e.target.value)}
                value={mensagem}
                name="c_comentario"
                id="c_comentario"
                placeholder="Mensagem"
              />
              <button onClick={() => onSubmit()} type="button" className="BotaoEnvia">
                <div className="TextEnviar">Enviar</div>
                <img src={IconSeta} className="ImgIconSeta" />
              </button>
            </form>
          </S.LeftContact>
          <S.RightContact>
            <S.ItemContact>
              <S.TextContact2>Alex Olobardi</S.TextContact2>
              <S.TextContact3>(11) 99254-4604</S.TextContact3>
              <S.TextContact3>alex@tudoempauta.com.br</S.TextContact3>
            </S.ItemContact>
            <S.ItemContact>
              <S.TextContact2>Marcelo Guaré</S.TextContact2>
              <S.TextContact3>(11) 99131-8322</S.TextContact3>
              <S.TextContact3>marcelo@tudoempauta.com.br</S.TextContact3>
            </S.ItemContact>
            <S.ItemContact>
              <S.TextContact2>Erika Digon</S.TextContact2>
              <S.TextContact3>(11) 99953-1048</S.TextContact3>
              <S.TextContact3>erika@tudoempauta.com.br</S.TextContact3>
            </S.ItemContact>
          </S.RightContact>
        </S.OutContact>
        <S.OutImgContact>
          <img src={Logo} />
        </S.OutImgContact>
      </S.BgContact>
    </>
  );
}

export default withRouter(Contato);
